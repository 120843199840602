<script>
import "./plugins/datepicker.css"
import "./plugins/datepicker.js"

export default {
  props: ['value', 'dateType'],
  mounted: function () {
    setTimeout(()=>{ this.init() },100);
  },
  methods:{
    init(){
  		var option = {
  			todayHighlight: true,
  			autoclose: true,
        orientation: "bottom",
        endDate: Date(),
  		}
  		// if(this.dateType=="dob") option = {
  		// 	orientation: "bottom",
  		// 	calendarWeeks: true,
  		// 	autoclose: true,
  		// 	todayHighlight: true,
  		// 	startView: 'decade',
  		// 	endDate: Date(),
    //   }
      if(this.value) this.$emit('input', this.value.dates("MM/DD/YYYY"))
  		$(this.$el).datepicker(Object.assign(option,this.config||{})).change((e)=>{
        this.$emit('input', e.target.value)
        $(this.$el).valid()
  		});
    }
  },
	watch: {
		value: function (v) {
      if(!v) return $(this.$el).datepicker("update", "");
			$(this.$el).datepicker("update", v.dates("MM/DD/YYYY"))
		}
	}
}
</script>

<template>
  <input type="text" class="form-control">
</template>
